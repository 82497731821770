import React, { useLayoutEffect } from "react";
import queryString from "query-string";
import window from "global/window";
import SEO from "@src/components/SEO";

// Universal link page, just redirects to the teacher app if user does not have native app
const IndexPage = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { ch, c } = parsedLocation;

  useLayoutEffect(() => {
    if (ch && c) {
      window.location.href = `https://teach.classdojo.com/#/classes/${c}/messaging/${ch}`;
    } else if (c) {
      window.location.href = `https://teach.classdojo.com/#/classes/${c}`;
    } else {
      window.location.href = `https://teach.classdojo.com/#/`;
    }
  }, [ch, c]);

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default IndexPage;
